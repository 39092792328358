import smartquotes from 'smartquotes';

import {
    TEMPLATE_ATTORNEY_SEARCH,
    TEMPLATE_NEWS_SEARCH,
    TEMPLATE_SITE_SEARCH,
} from '../constants';

export function isSearchTemplate(templateKeyword) {
    switch (templateKeyword) {
        case TEMPLATE_ATTORNEY_SEARCH:
        case TEMPLATE_NEWS_SEARCH:
        case TEMPLATE_SITE_SEARCH:
            return true;
        default:
            return false;
    }
}

export function getViewForTemplate(templateKeyword) {
    switch (templateKeyword) {
        case TEMPLATE_ATTORNEY_SEARCH:
            return 'attorney';
        case TEMPLATE_NEWS_SEARCH:
            return 'news';
        case TEMPLATE_SITE_SEARCH:
            return 'site';
        default:
            return null;
    }
}

export function removeDuplicateBlogPostContent(suggestions) {
    const existentNewsSuggestionsByName = suggestions.filter(suggestion => suggestion.template_keyword === 'news').reduce((acc, curr) => {
        acc[smartquotes(curr.name)] = true;
        return acc;
    }, {});

    return suggestions.filter(suggestion => (suggestion.template_keyword === 'blog_post' && !(smartquotes(suggestion.name) in existentNewsSuggestionsByName)) || (suggestion.template_keyword !== 'blog_post'));
}
