import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import WindowContext from './context/WindowContext';
import RenderModeContext from './context/RenderModeContext';
import { qualifyUrlInWindow } from '../utils/url';

// Wrap a component in a class component. This is useful if you
// need to supply a ref to a functional component, since refs
// only work when applied to class components.
function asClassComponent(Component) {
    return class ClassComponent extends React.Component { // eslint-disable-line react/prefer-stateless-function
        render() {
            return <Component {...this.props} />;
        }
    };
}

function withUrlsQualified(Component, qualifyKeys) {
    if (!Array.isArray(qualifyKeys)) {
        qualifyKeys = [];
    }

    return function ComponentWithUrlsQualified(props) {
        const qualifyPropsWithWindow = window => qualifyKeys.reduce((acc, key) => {
            acc[key] = qualifyUrlInWindow(props[key], window);
            return acc;
        }, {});

        return (
            <WindowContext.Consumer>
                {(window) => {
                    const qualifiedProps = qualifyPropsWithWindow(window);
                    const finalProps = { ...props, ...qualifiedProps };
                    return <Component {...finalProps} />;
                }}
            </WindowContext.Consumer>
        );
    };
}

function withWindowProp(Component) {
    return function ComponentWithWindowProp(props) {
        return (
            <WindowContext.Consumer>
                {window => <Component {...props} window={window} />}
            </WindowContext.Consumer>
        );
    };
}

function withRenderModeProp(Component) {
    return function ComponentWithRenderModeProp(props) {
        return (
            <RenderModeContext.Consumer>
                {renderMode => <Component {...props} renderMode={renderMode} />}
            </RenderModeContext.Consumer>
        );
    };
}

function withScrollSpy(TemplateComponent, getOrderedSectionIdsFromPropsFunc) {
    class TemplateComponentWithScrollSpy extends React.Component { // eslint-disable-line react/no-multi-comp
        constructor(props) {
            super(props);
            this.state = {
                currentSectionId: null,
            };

            const orderedSectionIds = getOrderedSectionIdsFromPropsFunc(props);

            this.reverseOrderedSections = orderedSectionIds.map(id => ({
                id,
                ref: React.createRef(),
            })).reverse();
            this.sectionRefsById = this.reverseOrderedSections.reduce((acc, cur) => {
                acc[cur.id] = cur.ref;
                return acc;
            }, {});
        }

        componentDidMount() {
            const { window } = this.props;
            const throttleInterval = 50;

            this.scrollSpy = _.throttle(() => {
                let currentSectionId = null;
                let lastSectionId = null;
                let hasFoundCurrentSection = false;

                this.reverseOrderedSections.forEach((section) => {
                    const node = ReactDOM.findDOMNode(section.ref.current); // eslint-disable-line react/no-find-dom-node
                    if (!node) {
                        return;
                    }

                    if (!lastSectionId) {
                        lastSectionId = section.id;
                    }

                    // If we hit the bottom of the page, then always highlight the last section. This prevents the short content issue at least for the last item.
                    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                        currentSectionId = lastSectionId;
                        hasFoundCurrentSection = true;
                    }

                    const sectionTop = node.getBoundingClientRect().top;
                    if (!hasFoundCurrentSection && sectionTop <= 0) {
                        currentSectionId = section.id;
                        hasFoundCurrentSection = true;
                    }
                });
                this.setState({ currentSectionId });
            }, throttleInterval);

            this.scrollSpy();
            setTimeout(() => {
                window.addEventListener('scroll', this.scrollSpy);
            }, 2000);

            this.navigateToHash();
        }

        componentWillUnmount() {
            const { window } = this.props;
            window.removeEventListener('scroll', this.scrollSpy);
        }

        navigateToHash() {
            const { window } = this.props;

            if (window.location.hash === '') {
                return;
            }

            const hashValue = window.location.hash.substr(1, window.location.hash.length);
            const currentSection = this.reverseOrderedSections.find(section => hashValue === section.id);

            if (!currentSection) { return; }

            const currentSectionId = currentSection.id;

            this.setState({ currentSectionId });
        }

        render() {
            return (
                <TemplateComponent
                    {...this.props}
                    sectionRefsById={this.sectionRefsById}
                    currentSectionId={this.state.currentSectionId}
                />
            );
        }
    }

    TemplateComponentWithScrollSpy.propTypes = {
        window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    };

    return TemplateComponentWithScrollSpy;
}

export {
    asClassComponent,
    withUrlsQualified,
    withWindowProp,
    withRenderModeProp,
    withScrollSpy,
};
