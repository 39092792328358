import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import Logo from './Logo.jsx';
import Takeover from './Takeover.jsx';
import { withWindowProp } from '../../hocs';
import { openNavTakeover, closeNavTakeover } from './actions';
import { PDF_RENDER_MODE, KEY_ESCAPE } from '../../../constants';
import styles from './styles/main.scss';

const mapDispatchToProps = dispatch => ({
    openNavTakeover(navOptions) {
        dispatch(openNavTakeover(navOptions));
    },
    closeNavTakeover() {
        dispatch(closeNavTakeover());
    },
});

class Nav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            applyNavButtonPositionFix: false,
        };

        this.takeoverRef = React.createRef();

        this.updateNavButtonClass = _.throttle(
            this.updateNavButtonClass.bind(this),
            100,
        );
    }

    componentDidMount() {
        this.props.window.addEventListener('keydown', (ev) => {
            if (ev.keyCode === KEY_ESCAPE) {
                this.props.closeNavTakeover();
            }
        });

        this.props.window.addEventListener('resize', this.updateNavButtonClass);
        this.updateNavButtonClass();
    }

    updateNavButtonClass() {
        const browserWidthWithScrollbar = _.get(this, 'props.window.innerWidth');
        const innerWidthWithoutScrollbar = _.get(this, 'props.window.document.body.clientWidth');

        const applyNavButtonPositionFix = _.get(this, 'state.applyNavButtonPositionFix');

        if (innerWidthWithoutScrollbar < 1440 && browserWidthWithScrollbar >= 1440) {
            if (!applyNavButtonPositionFix) {
                this.setState({
                    applyNavButtonPositionFix: true,
                });
            }
        } else if (applyNavButtonPositionFix) {
            this.setState({
                applyNavButtonPositionFix: false,
            });
        }
    }

    handleSearchClick(ev) {
        ev.preventDefault();
        this.props.openNavTakeover({
            menuIsOpen: false,
            searchIsOpen: true,
        });

        const isIE11 = document.getElementsByTagName('html')[0].classList.contains('ie11');

        setTimeout(() => {
            const autocompleteInput = _.get(this, [
                'takeoverRef',
                'navAutocompleteRef',
                'current',
                'autocompleteRef',
                'current',
                'autosuggestRef',
                'current',
                'input',
            ]);
            if (autocompleteInput) {
                if (!isIE11) {
                    autocompleteInput.focus();
                }
            }
        }, 500);
    }

    handleMenuClick(ev) {
        ev.preventDefault();
        this.props.openNavTakeover({
            menuIsOpen: true,
            searchIsOpen: false,
        });
    }

    render() {
        const {
            rootPages,
            searchPageUrl,
            navStyle,
            window,
            renderMode,
            isHomepage,
        } = this.props;

        const { applyNavButtonPositionFix } = this.state;

        const renderModeClass = renderMode === PDF_RENDER_MODE ? styles.isPdf : '';

        return (
            <React.Fragment>
                <div className={`${styles.navMain} ${renderModeClass}`}>
                    <Logo isHomepage={isHomepage} variant={isHomepage ? 'white' : 'normal'} />
                    <div className={classnames(styles.navButtons, {
                        [styles.navButtonsWithBackground]: navStyle === 'solid',
                        [styles.navButtonsWithBackgroundMobile]: isHomepage,
                        [styles.isHomepage]: isHomepage,
                        [styles.navButtonsPositionFix]: applyNavButtonPositionFix,
                    })}>
                        <button
                            className={`${styles.search} u-hide-in-print`}
                            aria-label="Site Search"
                            onClick={ev => this.handleSearchClick(ev)}>
                            <span className={`${styles.searchIcon} icon icon-search`} />
                        </button>
                        <button
                            className={`${styles.menu} u-hide-in-print`}
                            aria-label="Site Menu"
                            onClick={ev => this.handleMenuClick(ev)}>
                            <span className={`${styles.menuIcon} icon icon-menu`}>
                                <span className="path1 font-size-44" />
                                <span className="path2 font-size-44" />
                            </span>
                        </button>
                    </div>
                </div>
                <Takeover
                    ref={(connectedComponent) => {
                        this.takeoverRef = connectedComponent && connectedComponent.getWrappedInstance();

                        return this.takeoverRef;
                    }}
                    searchPageUrl={searchPageUrl}
                    rootPages={rootPages}
                    window={window} />
            </React.Fragment>
        );
    }
}

Nav.propTypes = {
    closeNavTakeover: PropTypes.func.isRequired,
    openNavTakeover: PropTypes.func.isRequired,
    window: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    rootPages: Takeover.propTypes.rootPages, // eslint-disable-line react/no-typos
    navStyle: PropTypes.oneOf(['basic', 'solid']),
    searchPageUrl: PropTypes.string,
    renderMode: PropTypes.string,
    isHomepage: PropTypes.bool,
};

Nav.defaultProps = {
    rootPages: [],
    searchPageUrl: '',
    navStyle: 'basic',
    renderMode: '',
    isHomepage: false,
};

const NavWithWindow = withWindowProp(Nav);
export default connect(null, mapDispatchToProps)(NavWithWindow);
