import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Slider from 'react-slick';
import Asset from '../Asset/index.jsx';
import ConditionalRender from '../ConditionalRender.jsx';
import styles from './slider.scss';

function getValidButtonProps(props) {
    return _.omit(props, ['slideCount', 'currentSlide']);
}

function PrevArrow(props) {
    const validProps = getValidButtonProps(props);
    return <button type="button" aria-label="Previous" {...validProps} />;
}

function NextArrow(props) {
    const validProps = getValidButtonProps(props);
    return <button type="button" aria-label="Next" {...validProps} />;
}

class Carousel extends React.Component {
    constructor(props) {
        super(props);

        this.sliderRef = React.createRef();
    }

    pause(ev) {
        this.sliderRef.current.slickPause();
    }

    render() {
        const {
            slides,
        } = this.props;

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            focusOnSelect: true,
            pauseOnFocus: true,
            pauseOnHover: true,
            prevArrow: <PrevArrow {...this.props} />,
            nextArrow: <NextArrow {...this.props} />,
        };

        return (
            <div onClick={ev => this.pause(ev)} onKeyDown={ev => this.pause(ev)} role="button" tabIndex="0">
                <Slider {...settings} ref={this.sliderRef}>
                    {slides.map((slide, idx) => (
                        <React.Fragment key={idx}>
                            <Asset asset={slide.asset} className={styles.asset} />
                            <ConditionalRender condition={slide.asset && slide.title}>
                                {slide.url ? 
                                  <a href={slide.url} className={`${styles.link}  type-carousel-overlay  white  bg-dark-blue`}>
                                    <p>{slide.title}</p>
                                    {slide.description && <p className='description'>{slide.description}</p>}
                                  </a> :
                                  <span className={`${styles.link}  type-carousel-overlay  white  bg-dark-blue`}>
                                  <p>{slide.title}</p>
                                  {slide.description && <p className='description'>{slide.description}</p>}
                                </span> 
                                }
                            </ConditionalRender>
                            {
                              slides.length > 1 && <div className={styles.bottomOverlay} />
                            }
                        </React.Fragment>
                    ))}
                </Slider>
            </div>
        );
    }
}

Carousel.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        asset: PropTypes.shape(Asset.propTypes),
    })),
};

Carousel.defaultProps = {
    slides: [],
};

export default Carousel;
