import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactModal from 'react-modal';
import { VelocityComponent } from 'velocity-react';
import Asset from '../Asset/index.jsx';
import ConditionalRender from '../ConditionalRender';
import MaybeLink from '../MaybeLink.jsx';
import { KEY_ENTER } from '../../../constants';
import styles from './careers-grid.scss';

function setOverlayIconClasses(isActive) {
    const classes = [
        styles.overlayIcon,
        'icon',
    ];

    if (isActive) {
        classes.push('icon-minus-circle');
    } else {
        classes.push('icon-plus-circle');
    }

    return classes.join('  ');
}

function VideoOrImage({
    asset,
    stillframe_asset,
    typeCategory,
    modalEvent,
    cropToggle,
}) {
    const inlineWrapperClassName = styles.gridImageWrapper;

    if (typeCategory === 'video') {
        return (
            <React.Fragment>
                <Asset
                    asset={stillframe_asset}
                    extraProps={{ className: `${styles.modalTrigger}`, onClick: modalEvent, 'data-object-fit': 'cover' }}
                    role="button"
                    tabIndex={0}
                    onKeyDown={ev => this.handleVideoKeyDown(ev)}
                    inlineWrapperClassName={inlineWrapperClassName}
                />
            </React.Fragment>
        );
    }

    const croppedImageClass = cropToggle ? styles.gridImageCroppedLeft : styles.gridImageCroppedRight;

    return (
        <div className={styles.gridImageCropped}>
            <Asset asset={asset} extraProps={{ className: croppedImageClass, 'data-object-fit': 'cover' }} />
        </div>
    );
}

class GridItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            videoIsActive: false,
            textOverlayActive: false,
            isModalOpen: false,
        };

        this.videoRef = React.createRef();
    }

    openModal() {
        this.setState({ isModalOpen: true });
    }

    closeModal() {
        this.setState({ isModalOpen: false });
    }

    handleVideoStart() {
        this.setState({ videoIsActive: true });
    }

    handleVideoEnd() {
        this.setState({ videoIsActive: false });
    }

    handleTextToggle(ev) {
        ev.preventDefault();
        this.setState(prevState => ({
            textOverlayActive: !prevState.textOverlayActive,
        }));
    }

    handleVideoKeyDown(ev) {
        ev.preventDefault();
        if (ev.keyCode === KEY_ENTER) {
            this.setState({ isModalOpen: true });
        }
    }

    handleKeyDown(ev) {
        ev.preventDefault();
        this.setState(prevState => ({
            textOverlayActive: !prevState.textOverlayActive,
        }));
    }

    render() {
        const {
            asset,
            stillframe_asset,
            crop_from_left_on_mobile,
            asset_overlay_text,
            asset_overlay_name,
            asset_overlay_position,
            headline,
            additional_content,
            idx,
            gridColor,
            headline_url,
        } = this.props;

        const layoutClass = idx % 2 === 0 ? 'twoThirdsOneThirdContainer' : 'oneThirdTwoThirdsContainer';
        const animationTypeVideo = this.state.videoIsActive ? 'fadeOut' : 'fadeIn';
        const animationTypeText = this.state.textOverlayActive ? 'fadeIn' : 'fadeOut';
        const typeCategory = _.get(asset, ['primaryAsset', 'typeCategory']);
        const isVideo = typeCategory === 'video';

        return (
            <div className={`${styles[layoutClass]} ${styles.container}`}>
                <article className={styles.imageContainer}>
                    <ConditionalRender condition={isVideo}>
                        <ReactModal
                            isOpen={this.state.isModalOpen}
                            overlayClassName={styles.overlay}
                            className={styles.content}
                            shouldCloseOnOverlayClick
                            shouldCloseOnEsc
                            onRequestClose={() => this.closeModal()}
                            onAfterOpen={() => this.videoRef.current.mediaRef.current.play()}>
                            <div className={styles.closeModalContainer}>
                                <button className={`${styles.closeModal}  icon  icon-close`} onClick={() => this.closeModal()} />
                            </div>
                            <Asset asset={asset} ref={this.videoRef} />
                        </ReactModal>
                    </ConditionalRender>

                    <VideoOrImage asset={asset} stillframe_asset={stillframe_asset} cropToggle={crop_from_left_on_mobile} typeCategory={typeCategory} modalEvent={ev => this.openModal(ev)} />

                    <VelocityComponent animation={animationTypeVideo}>
                        <div className={`${styles.imageTextOverlay}  white`}>
                            <ConditionalRender condition={isVideo}>
                                <button
                                    className={styles.overlayPlayButton}
                                    onClick={() => this.openModal()}
                                    onKeyDown={ev => this.handleVideoKeyDown(ev)}
                                />
                            </ConditionalRender>

                            <blockquote className={`${styles.imageQuote}  blockquote`}>
                                {asset_overlay_text}
                            </blockquote>
                            <p className={styles.imageAuthor}>
                                {asset_overlay_name}
                            </p>
                            <p className={styles.imageAuthorPosition}>
                                {asset_overlay_position}
                            </p>
                        </div>
                    </VelocityComponent>
                </article>

                <MaybeLink href={headline_url} className={`${styles.textContainer}  ${gridColor}  white`} fallbackTag="div">
                    <h3 className={styles.text}>{headline}</h3>
                    <ConditionalRender condition={additional_content}>
                        <button className={setOverlayIconClasses(this.state.textOverlayActive)} onClick={ev => this.handleTextToggle(ev)} onKeyDown={ev => this.handleKeyDown(ev)} />
                        <VelocityComponent animation={animationTypeText}>
                            <p className={styles.textOverlay}>{additional_content}</p>
                        </VelocityComponent>
                    </ConditionalRender>
                    <ConditionalRender condition={headline_url}>
                        <span className={`${styles.careersCaret} white has-hover-state u-hide-in-print icon icon-arrow-button-right`} />
                    </ConditionalRender>
                </MaybeLink>
            </div>
        );
    }
}

VideoOrImage.propTypes = {
    asset: PropTypes.shape(Asset.propTypes),
    stillframe_asset: PropTypes.shape(Asset.propTypes),
    typeCategory: PropTypes.string,
    modalEvent: PropTypes.func,
    cropToggle: PropTypes.bool,
};

VideoOrImage.defaultProps = {
    asset: {},
    stillframe_asset: {},
    typeCategory: '',
    modalEvent: _.noop,
    cropToggle: false,
};

GridItem.propTypes = {
    asset: PropTypes.shape(Asset.propTypes),
    stillframe_asset: PropTypes.shape(Asset.propTypes),
    asset_overlay_text: PropTypes.string,
    asset_overlay_name: PropTypes.string,
    asset_overlay_position: PropTypes.string,
    headline: PropTypes.string,
    headline_url: PropTypes.string,
    additional_content: PropTypes.string,
    gridColor: PropTypes.string,
    idx: PropTypes.number,
    crop_from_left_on_mobile: PropTypes.bool,
};

GridItem.defaultProps = {
    asset: {},
    stillframe_asset: {},
    asset_overlay_text: '',
    asset_overlay_name: '',
    asset_overlay_position: '',
    headline: '',
    headline_url: '',
    additional_content: '',
    gridColor: '',
    idx: '',
    crop_from_left_on_mobile: false,
};

export default GridItem;
