import {
    createStore,
    combineReducers,
    compose,
    applyMiddleware,
} from 'redux';
import ReduxThunk from 'redux-thunk';
import initSearch from '@rubyapps-mvc/redux-search';
import {
    reducer as search,
    getInitialState as getInitialSearchState,
} from '@rubyapps-mvc/redux-search/reducers';
import {
    layout,
    getInitialGlobalState,
} from './globalReducers';
import nav from './reactComponents/elements/Nav/reducers';
import {
    reducer as attorney,
    getInitialState as getInitialAttorneyState,
} from './reactComponents/templates/attorney/reducers';
import {
    reducer as practiceLanding,
    getInitialState as getInitialPracticeLandingState,
} from './reactComponents/templates/practice_landing/reducers';
import {
    reducer as statsTakeover,
    getInitialState as getInitialStatsTakeoverState,
    getReduxKey as getStatsTakeoverReduxKey,
} from './reactComponents/elements/StatsTakeover/reducers';
import {
    TEMPLATE_ATTORNEY,
    TEMPLATE_PRACTICE_LANDING,
    TEMPLATE_HOMEPAGE,
} from './constants';
import {
    isSearchTemplate,
    getViewForTemplate,
} from './utils/search';
import scrollOnReceiveResultsMiddleware from './scrollOnReceiveResultsMiddleware';

function createReduxStoreWithOptions({ props, window, cookieHandler }) {
    const { templateKeyword } = props;

    const reducers = {
        layout,
        nav,
    };

    const preloadedState = {
        ...getInitialGlobalState(window),
    };

    if (templateKeyword === TEMPLATE_ATTORNEY) {
        reducers.attorney = attorney;
        preloadedState.attorney = getInitialAttorneyState(props);
    }

    if (templateKeyword === TEMPLATE_PRACTICE_LANDING) {
        reducers.practiceLanding = practiceLanding;
        preloadedState.practiceLanding = getInitialPracticeLandingState(props);
    }

    if (templateKeyword === TEMPLATE_HOMEPAGE) {
        const statsTakeoverReduxKey = getStatsTakeoverReduxKey();
        reducers[statsTakeoverReduxKey] = statsTakeover;
        preloadedState[statsTakeoverReduxKey] = getInitialStatsTakeoverState(props);
    }

    const searchQueryConfig = {
        resultsPerPage: props.defaultResultsPerPage,
        ignores: {},
    };
    if (isSearchTemplate(templateKeyword)) {
        reducers.search = search;
        preloadedState.search = getInitialSearchState({
            ...searchQueryConfig,
            query: window.location.search,
            viewState: props.initialSearchViewState,
        });
    }

    const rootReducer = combineReducers(reducers);

    const middlewares = [
        ReduxThunk,
        scrollOnReceiveResultsMiddleware,
    ];

    const storeComposeArgs = [applyMiddleware(...middlewares)];
    const composeEnhancers = compose;
    const devTool = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (devTool && process.env.NODE_ENV !== 'production') {
        storeComposeArgs.push(devTool());
    }

    const store = createStore(rootReducer, preloadedState, composeEnhancers(...storeComposeArgs));

    if (isSearchTemplate(templateKeyword)) {
        initSearch({
            ...searchQueryConfig,
            store,
            url: `${window.location.protocol}//${window.location.hostname}/${window.location.pathname}`,
            view: getViewForTemplate(templateKeyword),
            hiddens: {
                _ajax: true,
            },
        });
    }

    return store;
}

export default createReduxStoreWithOptions;
