import React from 'react';
import PropTypes from 'prop-types';
import CaptionedMedia from './CaptionedMedia/index.jsx';
import Content from './modules/Content.jsx';
import Accordion from './modules/AccordionModule.jsx';
import Carousel from '../elements/Carousel/index.jsx';

function getRepeaterComponentsMap() {
    const componentMapping = {
        captioned_media: CaptionedMedia,
        content: Content,
        accordion: Accordion,
        carousel: Carousel,
    };

    return componentMapping;
}

function getRepeaterComponentFromType(type) {
    const componentMapping = getRepeaterComponentsMap();

    return componentMapping[type];
}

function Repeater({ type, props }) {
    if (!type) {
        return null;
    }

    const Component = getRepeaterComponentFromType(type);

    if (!Component) {
        console.error(`${type} is not a valid repeater component.`);
        return null;
    }

    return (
        <Component {...props} />
    );
}

Repeater.propTypes = {
    type: PropTypes.oneOf(Object.keys(getRepeaterComponentsMap())),
    props: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Repeater.defaultProps = {
    type: null,
    props: {},
};

export default Repeater;
