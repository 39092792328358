import React from 'react';
import PropTypes from 'prop-types';
import QualifiedLink from './QualifiedLink';
import styles from './careers-navigation.scss';

function CareersNavigation({
    navItems,
}) {
    return (
        <ul className="l-3-columns-grid">
            {navItems.map((navItem, idx) => (
                <li className={`${styles.navLinkContainer} l-1m-3t-3d-column-item`} key={idx}>
                    <QualifiedLink href={navItem.link} className={`${styles.navLink}  white`}>
                        <span>{navItem.text}</span>
                    </QualifiedLink>
                </li>
            ))}
        </ul>
    );
}

CareersNavigation.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
    })),
};

CareersNavigation.defaultProps = {
    navItems: [],
};

export default CareersNavigation;
